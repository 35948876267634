
function isNavigatorIE() {
    if(false || !!document['documentMode']){
      return true;
    } else {
      return false;
    }
}

function changeDatepickerLang(lang) {
  if($('#fechaIE')[0] != undefined){
    $.datepicker.setDefaults($.datepicker.regional[lang]);
  }
}

  // mi
$.datepicker.regional['es'] = {
  closeText: 'Cerrar',
  prevText: '< Ant',
  nextText: 'Sig >',
  currentText: 'Hoy',
  monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
  monthNamesShort: ['Ene','Feb','Mar','Abr', 'May','Jun','Jul','Ago','Sep', 'Oct','Nov','Dic'],
  dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
  dayNamesShort: ['Dom','Lun','Mar','Mié','Juv','Vie','Sáb'],
  dayNamesMin: ['Do','Lu','Ma','Mi','Ju','Vi','Sá'],
  weekHeader: 'Sm',
  dateFormat: 'dd/mm/yy',
  firstDay: 1,
  isRTL: false,
  showMonthAfterYear: false,
  yearSuffix: ''
  };
  $.datepicker.setDefaults($.datepicker.regional['es']);
 
  // literales datepicker euskera
$.datepicker.regional['eu'] = {
 closeText: 'Egina',
 prevText: '<Aur',
 nextText: 'Hur>',
 currentText: 'Gaur',
 monthNames: ['Urtarrila','Otsaila','Martxoa','Apirila','Maiatza','Ekaina',
 'Uztaila','Abuztua','Iraila','Urria','Azaroa','Abendua'],
 monthNamesShort: ['Urt','Ots','Mar','Api','Mai','Eka',
 'Uzt','Abu','Ira','Urr','Aza','Abe'],
 dayNames: ['Igandea','Astelehena','Asteartea','Asteazkena','Osteguna','Ostirala','Larunbata'],
 dayNamesShort: ['Iga','Ast','Ast','Ast','Ost','Ost','Lar'],
 dayNamesMin: ['Ig','Al','As','Az','Og','Or','Lr'],
 dateFormat: 'yy/mm/dd', firstDay: 1,
 isRTL: false};
 // $.datepicker.setDefaults($.datepicker.regional['eu']);